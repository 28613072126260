import {
  GrantDashboard,
  ProgramDashboard,
} from "../../../interfaces/interfaces";
import { formatNumber } from "../../../utils";
import { TFunction } from "i18next";
import { SORT_TYPES } from "../../../consts";
import { SortTypes } from "../../../interfaces/types";
import { LinkComponent } from "../dashboard-routes.component";

export const getProgramColumns = ({
  t,
}: {
  t: TFunction;
}): {
  header: string;
  property: keyof ProgramDashboard;
  sum?: (row: ProgramDashboard, acc: number) => number;
  format?: (value: string | JSX.Element) => string | JSX.Element;
  sort: boolean;
  sortType: SortTypes;
  customComponent?: (row: ProgramDashboard) => JSX.Element;
}[] => [
  {
    header: t("company.tabs.dashboard.dataTable.programId"),
    property: "programCode",
    sort: true,
    customComponent: ({ programCode }: ProgramDashboard) =>
      LinkComponent({ label: programCode, path: `program/${programCode}` }),
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.dataTable.programName"),
    property: "programName",
    sort: true,
    sortType: SORT_TYPES.string,
  },
  {
    header: t("company.tabs.dashboard.dataTable.currentMonthExpense"),
    property: "currentMonthExpense",
    sum: (row: ProgramDashboard, acc: number) => +row.currentMonthExpense + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.dataTable.yearExpense"),
    property: "yearExpense",
    sum: (row: ProgramDashboard, acc: number) => +row.yearExpense + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.dataTable.currentMonthGrantFunding"),
    property: "currentMonthGrantFunding",
    sum: (row: ProgramDashboard, acc: number) =>
      +row.currentMonthGrantFunding + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.dataTable.yearGrantFunding"),
    property: "yearGrantFunding",
    sum: (row: ProgramDashboard, acc: number) => +row.yearGrantFunding + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.dataTable.yearSelfFunded"),
    property: "yearSelfFunded",
    sum: (row: ProgramDashboard, acc: number) => +row.yearSelfFunded + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
];

export const getGrantColumns = ({
  t,
}: {
  t: TFunction;
}): {
  header: string;
  property: keyof GrantDashboard;
  sum?: (row: GrantDashboard, acc: number) => number;
  format?: (value: string | JSX.Element) => string | JSX.Element;
  sort: boolean;
  sortType: SortTypes;
  customComponent?: (row: GrantDashboard) => JSX.Element;
}[] => [
  {
    header: t("company.tabs.dashboard.grantTable.id"),
    property: "grantCode",
    sort: true,
    sortType: SORT_TYPES.number,
    customComponent: ({ grantCode }: GrantDashboard) =>
      LinkComponent({ label: grantCode, path: `grant/${grantCode}` }),
  },
  {
    header: t("company.tabs.dashboard.grantTable.name"),
    property: "grantName",
    sort: true,
    sortType: SORT_TYPES.string,
  },
  {
    header: t("company.tabs.dashboard.grantTable.remainingBilling"),
    property: "remainingBillingMonths",
    sort: true,
    sortType: SORT_TYPES.number,
    //todo check the table
    format: (value: string | JSX.Element) => value || "0",
  },
  {
    header: t("company.tabs.dashboard.grantTable.budget"),
    property: "totalBudget",
    sum: (row: GrantDashboard, acc: number) => +row.totalBudget + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.grantTable.amount"),
    property: "amountSpent",
    sum: (row: GrantDashboard, acc: number) => +row.amountSpent + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.grantTable.remainingBalance"),
    property: "remainingBalance",
    sum: (row: GrantDashboard, acc: number) => +row.remainingBalance + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.grantTable.programId"),
    property: "programCode",
    sort: true,
    sortType: SORT_TYPES.number,
  },
];

import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLoadData } from "../../../hooks/useLoadData";
import { CompanyContext } from "../../../context/company-context";
import dashboardApi from "../../../api/dashboard-api";
import fileApi from "../../../api/file-api";
import Spinner from "../../../components/shared/spinner/spinner.component";
import Table from "../../../components/shared/table/table.component";
import { useTranslation } from "react-i18next";
import {
  getFormattedDateMonthFirst,
  getMonthFromDate,
  getMonthFromPeriod,
  getStaticColumns,
  sortMonthAndYearValue,
} from "./helpers";
import { DetailsByGrant } from "../../../interfaces/interfaces";
import { formatNumber } from "../../../utils";
import { SORT_TYPES, ROUTE_PATHS } from "../../../consts";
import BackTo from "../../../components/shared/back-to/back-to.component";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import "./grant-dashboard.component.scss";

type DisplayTypes = "summary" | "period";

export default function GrantDashboard(): JSX.Element {
  const { t } = useTranslation();
  const { grantId } = useParams();
  const { company } = useContext(CompanyContext);
  const [periodStart, setPeriodStart] = useState<string>("");
  const [periodEnd, setPeriodEnd] = useState<string>("");
  const [periods, setPeriods] = useState<{
    periodStart: string;
    periodEnd: string;
  }>({
    periodStart: "",
    periodEnd: "",
  });
  const [displayType, setDisplayType] = useState<DisplayTypes>("summary");
  const [grantDashboard, isLoading] = useLoadData({
    fetcher: useCallback(
      () =>
        dashboardApi.getGrantsDetails({
          companyId: company?.id,
          grantCode: grantId,
          ...periods,
        }),
      [company?.id, grantId, periods]
    ),
  });

  const rowsToDisplay = useMemo(
    () => [
      ...(grantDashboard?.employeeDetailsByGrant?.map((employeeDetails) => ({
        ...employeeDetails,
        accountName: employeeDetails.jobFunctionAcronym,
        ...(employeeDetails.isJobFunctionForFringeBenefits && {
          sticky: true,
        }),
      })) || []),
      ...(grantDashboard?.nonPersonalDetailsByGrant?.map(
        (nonPersonalDetails) => ({
          ...nonPersonalDetails,
          ...(nonPersonalDetails.isAccountForIndirectCostAllocation && {
            sticky: true,
          }),
        })
      ) || []),
    ],
    [grantDashboard]
  );

  const uniqueMonth = useMemo<string[]>(() => {
    const allMonth = rowsToDisplay.reduce(
      (acc, { totalSpentByMonth }) => ({
        ...acc,
        ...totalSpentByMonth,
      }),
      {}
    );
    return Object.keys(allMonth);
  }, [rowsToDisplay]);
  const columns = useMemo<
    {
      header: string;
      render?: (row: DetailsByGrant) => string | JSX.Element;
      property: keyof DetailsByGrant;
      sum?: (row: DetailsByGrant, acc: number) => number;
      format?: (value: string | JSX.Element) => string | JSX.Element;
    }[]
  >(
    () => [
      ...getStaticColumns({ t, showBalance: displayType === "summary" }),
      ...(displayType === "period"
        ? uniqueMonth.sort(sortMonthAndYearValue).map((month) => ({
            header: `${month}, $`,
            property: month,
            sum: (row: DetailsByGrant, acc: number) =>
              (+row.totalSpentByMonth[month] || 0) + acc,
            format: (value: string | JSX.Element) => formatNumber(+value),
            sort: true,
            sortType: SORT_TYPES.number,
          }))
        : []),
    ],
    [t, uniqueMonth, displayType]
  );

  const rowsWithMonth = useMemo<DetailsByGrant[]>(
    () =>
      rowsToDisplay.map((item) => {
        return {
          ...item,
          ...uniqueMonth.reduce(
            (acc, month) => ({
              ...acc,
              [month]: item.totalSpentByMonth[month],
            }),
            {}
          ),
        };
      }),
    [rowsToDisplay, uniqueMonth]
  );

  const availableMonth = useMemo(
    () =>
      getMonthFromPeriod({
        start: grantDashboard.grantStartDate,
        end: grantDashboard.grantEndDate,
      }),
    [grantDashboard]
  );

  const startOptions = useMemo<string[]>(() => {
    if (!periodEnd) {
      return availableMonth;
    }
    const index = availableMonth.indexOf(periodEnd);
    return availableMonth.filter((_, i) => index > i);
  }, [availableMonth, periodEnd]);

  const endOptions = useMemo<string[]>(() => {
    if (!periodStart) {
      return availableMonth;
    }
    const index = availableMonth.indexOf(periodStart);
    return availableMonth.filter((_, i) => index < i);
  }, [availableMonth, periodStart]);

  const applyPeriod = useCallback(
    () =>
      setPeriods({
        periodStart,
        periodEnd,
      }),
    [periodEnd, periodStart]
  );
  return (
    <div className="grant-dashboard">
      <div className="header flex-fill justify-content-between">
        <BackTo
          link="/dashboard"
          title={`${t("company.tabs.dashboard.grantDetails.grant")} ${
            grantDashboard.grantName
          } / ${grantId}`}
        />
        <div className="right-side gap-3">
          <div className="effective-period">
            {`${t(
              "company.tabs.dashboard.grantDetails.effectivePeriod"
            )} ${getFormattedDateMonthFirst(
              grantDashboard.grantStartDate
            )} - ${getFormattedDateMonthFirst(grantDashboard.grantEndDate)}`}
          </div>
          <DownloadButtonComponent
            fileName={`grant-dashboard-${grantId}.xlsx`}
            label={t("general.export")}
            downloadHandler={() =>
              fileApi.downloadFile(
                `${ROUTE_PATHS.company}/${company.id}/dashboard/details-by-grant/${grantId}/export?periodStart=${periods.periodStart}&periodEnd=${periods.periodEnd}`
              )
            }
          />
        </div>
      </div>
      <div className="sub-header">
        <div className="filter gap-3">
          <a
            className={displayType === "summary" ? "active" : ""}
            onClick={() => setDisplayType("summary")}
          >
            {t("company.tabs.dashboard.grantDetails.grantSummary")}
          </a>
          <a
            onClick={() => {
              setDisplayType("period");
              setPeriods({
                periodStart: getMonthFromDate(grantDashboard.grantStartDate),
                periodEnd: getMonthFromDate(grantDashboard.grantEndDate),
              });
            }}
            className={displayType === "period" ? "active" : ""}
          >
            {t("company.tabs.dashboard.grantDetails.byPeriod")}
          </a>
        </div>
        {displayType === "period" && (
          <div className="period-controls gap-3">
            <div>{t("company.tabs.dashboard.grantDetails.grantPeriod")}</div>
            <select
              className="form-select"
              value={periodStart}
              onChange={({ target }) => setPeriodStart(target.value)}
              name="from"
            >
              <option disabled value="">
                from
              </option>
              {startOptions.map(
                (month): JSX.Element => (
                  <option key={`from-${month}`} value={month}>
                    {month}
                  </option>
                )
              )}
            </select>
            <select
              value={periodEnd}
              onChange={({ target }) => setPeriodEnd(target.value)}
              className="form-select"
              name="to"
            >
              <option disabled value="">
                to
              </option>
              {endOptions.map(
                (month): JSX.Element => (
                  <option key={`to-${month}`} value={month}>
                    {month}
                  </option>
                )
              )}
            </select>
            <button
              disabled={!(periodStart || periodEnd)}
              className="btn btn-primary"
              onClick={() => applyPeriod()}
            >
              {t("general.apply")}
            </button>
          </div>
        )}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          data={rowsWithMonth}
          presortBy={{
            property: "accountName",
            sortDirection: "up",
            sortType: "string",
          }}
          isFooter
          filter={(row: DetailsByGrant) => !!row?.jobFunctionAcronym}
          filteredTotalTitle={t(
            "company.tabs.grants.finData.list.separatorTitle"
          )}
          totalTitle={t("company.tabs.grants.finData.list.totalTitle")}
          restTotalTitle={t(
            "company.tabs.grants.finData.list.totalSecondTitle"
          )}
          filteredHeader={t(
            "company.tabs.dashboard.programDetails.employeeCompensation"
          )}
          restHeader={t(
            "company.tabs.dashboard.programDetails.notPersonalExpenses"
          )}
        />
      )}
    </div>
  );
}

export const COMPANY_TYPES = {
  standAlone: "standAlone",
  parent: "parent",
  affiliate: "affiliate",
  serviceProvider: "serviceProvider",
};

export const TYPES_TO_UI = {
  STANDALONE: "Stand-alone",
  PARENT: "Parent",
  AFFILIATES: "Affiliates",
  SERVICE_PROVIDER: "Service Provider",
};

export const CURRENCIES = {
  usd: "USD",
};

export const LANGUAGES = {
  english: "English",
};

export const COMPANY_PARTS = {
  details: "details",
  users: "users",
  mcoa: "mcoa",
  programs: "programs",
  jobFunctions: "job-functions",
  billingAllocationMethod: "control-center",
  grants: "grants",
  gl: "gl",
  payrollRegister: "payrollRegister",
  documents: "documents",
};

import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AxiosResponse } from "axios";
import { DmsFindInvoicesResult } from "../../../../interfaces/interfaces";
import { USER_ROLE_LABELS, USER_ROLES } from "../../../../consts";
import documentsApi from "../../../../api/documents-api";
import { getCompanyUrl } from "../../../companies/utils";
import { CompanyContext } from "../../../../context/company-context";
import { UserContext } from "../../../../context/user.context";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import UploadButtonComponent from "../../../../components/shared/upload/upload-button/upload-button.component";
import "./action-header.component.scss";

interface ActionHeaderProps {
  grantsByPeriod: Array<string>;
  selectedPeriod: string;
  selectedGrant: string;
  setSelectedGrant: (grant: string) => void;
  setUploadResult: (result: DmsFindInvoicesResult) => void;
  reloadGLs: () => void;
}

export default function ActionHeaderComponent({
  grantsByPeriod,
  selectedGrant,
  setSelectedGrant,
  setUploadResult,
  reloadGLs,
  selectedPeriod,
}: ActionHeaderProps): JSX.Element {
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isUserAdmin = user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN];
  const isReadonly = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_USER],
    [user.role]
  );
  return (
    <div className="documents__header">
      <div className="d-flex justify-content-end align-items-end gap-5">
        <div>
          <label htmlFor="grant">{t("company.tabs.documents.grant")}</label>
          <div className="select-wrapper">
            <select
              onChange={(e) => {
                setSelectedGrant(e.target.value);
              }}
              className="form-select"
              id="grant"
            >
              <option value="">None</option>
              {grantsByPeriod?.map?.((grant) => (
                <option key={grant} value={grant}>
                  {grant}
                </option>
              ))}
            </select>
          </div>
        </div>
        <UploadButtonComponent
          title={t("company.tabs.documents.upload")}
          disabled={isReadonly}
          multiple
          upload={(files): Promise<AxiosResponse> => {
            const formData = new FormData();
            const fileLength = files.length;
            for (let x = 0; x < fileLength; x++) {
              formData.append("file", files[x]);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return documentsApi
              .uploadInvoices(
                formData,
                company.id,
                selectedPeriod,
                selectedGrant
              )
              .then((res) => {
                if (res.errors?.length) {
                  errorHandler(undefined, res.errors?.[0]?.message);
                } else {
                  setUploadResult(res);
                  reloadGLs();
                }
              });
          }}
        />
        <button
          className="btn btn-primary"
          onClick={() =>
            navigate(
              isUserAdmin
                ? `${getCompanyUrl(company.id)}/documents/download`
                : "/documents/download"
            )
          }
        >
          {t("company.tabs.documents.download")}
        </button>
        <button
          disabled={isReadonly}
          onClick={() =>
            navigate(
              isUserAdmin
                ? `${getCompanyUrl(company.id)}/documents/settings`
                : "/documents/settings"
            )
          }
          className="btn btn-primary"
        >
          {t("general.settings")}
        </button>
      </div>
    </div>
  );
}

import { TFunction } from "i18next";
import { Grant } from "../../../interfaces/interfaces";
import { Link } from "react-router-dom";
import { SortTypes } from "../../../interfaces/types";
import { formatDate } from "../../../utils";

export const getColumns = ({
  t,
  pathname,
  isUserAdmin,
}: {
  t: TFunction;
  pathname: string;
  isUserAdmin: boolean;
}): {
  header: string;
  property: keyof Grant;
  sort?: boolean;
  sortType?: SortTypes;
  render?: (data: Grant) => JSX.Element | string;
  format?: (value: string | JSX.Element) => string | JSX.Element;
}[] => [
  {
    header: "",
    property: "actionList" as const,
    render: ({ id: grantId }: Grant): JSX.Element => (
      <div className="dropdown">
        <p className="dropdown-toggle" data-bs-toggle="dropdown">
          {t("company.tabs.grants.list.actions.title") as JSX.Element}
        </p>
        <ul className="dropdown-menu">
          <li>
            <Link
              className="text-decoration-none dropdown-item"
              to={`${pathname}/grant/${grantId}`}
            >
              {t("company.tabs.grants.list.actions.details") as JSX.Element}
            </Link>
          </li>
          <li>
            <Link
              className="text-decoration-none dropdown-item"
              to={`${pathname}/grant/${grantId}/budget/${grantId}`}
            >
              {t("company.tabs.grants.list.actions.finData") as JSX.Element}
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: t("company.tabs.grants.list.id"),
    property: "code",
    sort: true,
    sortType: "number",
  },
  {
    header: t("company.tabs.grants.list.name"),
    property: "name",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.classification"),
    property: "grantClassification",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.startDate"),
    property: "grantBudgetStart",
    sort: true,
    sortType: "string",
    format: (value) => {
      if (typeof value === "string") {
        return formatDate({ dateString: value });
      }
      return "";
    },
  },
  {
    header: t("company.tabs.grants.list.endDate"),
    property: "grantBudgetEnd",
    sort: true,
    sortType: "string",
    format: (value) => {
      if (typeof value === "string") {
        return formatDate({ dateString: value });
      }
      return "";
    },
  },
  {
    header: t("company.tabs.grants.list.currentStatus"),
    property: "isActive",
    render: (data: Grant) => t(`general.${data.isActive}`),
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.programName"),
    render: (data: Grant) => data.program?.name,
    property: "program.name",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.programId"),
    render: (data: Grant) => data.program?.code,
    property: "program.code",
    sort: true,
    sortType: "string",
  },
];
